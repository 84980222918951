export const getAppHubCategories = (
  t: Function
): { [key: string]: string }[] => {
  return [
    {
      id: 'accounting_and_finance',
      name: t('appHubCategories.accounting_and_finance'),
    },
    { id: 'analytics', name: t('appHubCategories.analytics') },
    {
      id: 'calendar_and_scheduling',
      name: t('appHubCategories.calendar_and_scheduling'),
    },
    {
      id: 'collaboration_management',
      name: t('appHubCategories.collaboration_management'),
    },
    {
      id: 'customer_relations',
      name: t('appHubCategories.customer_relations'),
    },
    { id: 'customer_support', name: t('appHubCategories.customer_support') },
    { id: 'developer_tools', name: t('appHubCategories.developer_tools') },
    { id: 'devices_and_iot', name: t('appHubCategories.devices_and_iot') },
    { id: 'doc_management', name: t('appHubCategories.doc_management') },
    { id: 'education', name: t('appHubCategories.education') },
    { id: 'healthcare', name: t('appHubCategories.healthcare') },
    { id: 'government', name: t('appHubCategories.government') },
    { id: 'marketing', name: t('appHubCategories.marketing') },
    { id: 'partner_solutions', name: t('appHubCategories.partner_solutions') },
    { id: 'platform', name: t('appHubCategories.platform') },
    { id: 'productivity', name: t('appHubCategories.productivity') },
    {
      id: 'project_management',
      name: t('appHubCategories.project_management'),
    },
    { id: 'human_resources', name: t('appHubCategories.human_resources') },
    {
      id: 'security_and_compliance',
      name: t('appHubCategories.security_and_compliance'),
    },
    { id: 'social', name: t('appHubCategories.social') },
    {
      id: 'strategy_and_team_planning',
      name: t('appHubCategories.strategy_and_team_planning'),
    },
    { id: 'other', name: t('appHubCategories.other') },
  ]
}
