import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { navigate } from 'gatsby'
import { Button, Checkbox, Icon } from '@momentum-ui/react'
import ActionInput from '../../components/Integration/ActionInput'
import ImageSelector from '../../components/ImageSelector'
import defaultGreen from '../../assets/images/defaultGreen.png'
import defaultPurple from '../../assets/images/defaultPurple.png'
import defaultOrange from '../../assets/images/defaultOrange.png'
import {
  DESCRIPTION_VALIDATOR,
  EMAIL_VALIDATOR,
  WEBSITE_URL_AND_EMAIL_VALIDATOR,
  INTEGRATION_NAME_VALIDATOR,
  NAME_VALIDATOR,
  REQUIRED_FIELD_VALIDATOR,
  SUMMARY_VALIDATOR,
  TAGLINE_VALIDATOR,
  TESTING_INSTRUCTIONS_VALIDATOR,
  URL_OR_EMPTY_VALIDATOR,
  WEBSITE_URL_VALIDATOR,
} from '../../components/Integration/integrationValidators'
import ScreenshotSelector from '../../components/ScreenshotSelector'
import Link from '../../components/Link'
import MetricsService from '../../services/metricsService'
import { INTEGRATION_ACTION, PAGE_VIEWED } from '../../constants/metrics'
import { IIntegration } from '../../interfaces/integrationInterfaces'
import httpService from '../../services/httpService'
import { getBffBaseUrl } from '../../services/configService'
import { logError } from '../../services/loggerService'
import { useSelector } from 'react-redux'
import { retrieveToken } from '../../state/auth'
import { AppState } from '../../state/store'
import { getCIUrls } from '../../utils/auth.utils'
import ErrorAlert from '../../components/ErrorAlert'
import { getAppHubCategories } from './config'
import InlineErrorMessage from '../../components/InlineErrorMessage'
import { INTEGRATION_SUBMITTED } from '../../constants/integrations'
import {
  createHttpConfig,
  removeSpecialCharacters,
} from '../../utils/general.utils'

interface ISubmitIntegrationProps {
  integration: IIntegration
}

const SubmitIntegration: React.FC<ISubmitIntegrationProps> = ({
  integration,
}) => {
  const { t } = useTranslation()
  const token = useSelector((state: AppState) => retrieveToken(state))
  const metricsService = MetricsService.getInstance()

  const appHubCategory = getAppHubCategories(t)
  const getPrepopulatedCategories = (
    categories?: string[]
  ): { [key: string]: string }[] => {
    const filledOutCategories: { [key: string]: string }[] = []
    categories?.forEach((category: string) => {
      const categoryObj = appHubCategory.find((cat) => cat.id === category)
      if (categoryObj) {
        filledOutCategories.push(categoryObj)
      }
    })
    return filledOutCategories
  }

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: any
  }>({})
  const [hasError, setHasError] = useState<boolean>(false)
  const [categoryTouched, setCategoryTouched] = useState<boolean>(false)
  const [formInitial, setFormInitial] = useState<any>({
    appContext: ['contact_center'],
    name: removeSpecialCharacters(integration?.name) || '',
    logo: integration?.logo || '',
    instructions: integration?.instructions || '',
    tagLine: integration?.tagLine || '',
    shortTagLine: integration?.shortTagLine || '',
    description: integration?.description || '',
    categories: getPrepopulatedCategories(integration?.categories) || [],
    productUrl: integration?.productUrl || '',
    supportUrl: integration?.supportUrl || '',
    privacyUrl: integration?.privacyUrl || '',
    companyName: integration?.companyName || '',
    companyUrl: integration?.companyUrl || '',
    contactName: integration?.contactName || '',
    contactEmail: integration?.contactEmail || '',
    ciscoContact: integration?.ciscoContact || '',
    screenshot1: integration?.screenshot1 || '',
    screenshot2: integration?.screenshot2 || '',
    screenshot3: integration?.screenshot3 || '',
    videoUrl: integration?.videoUrl || '',
  })
  const [screenshotOneUploaded, setScreenshotOneUploaded] = useState<boolean>(
    formInitial.screenshot1 && true
  )
  const [screenshotTwoUploaded, setScreenshotTwoUploaded] = useState<boolean>(
    formInitial.screenshot2 && true
  )
  const [screenshotThreeUploaded, setScreenshotThreeUploaded] = useState<
    boolean
  >(formInitial.screenshot3 && true)
  const [screenshotRequirementError, setScreenshotRequirementError] = useState<
    boolean
  >(false)

  const [formDiff, setFormDiff] = useState<any>({
    ...formInitial,
  })

  const handleScreenshotState = (uploaded: boolean, name: string): void => {
    if (name.toLowerCase() === 'screenshot1') {
      setScreenshotOneUploaded(uploaded)
    } else if (name.toLowerCase() === 'screenshot2') {
      setScreenshotTwoUploaded(uploaded)
    } else if (name.toLowerCase() === 'screenshot3') {
      setScreenshotThreeUploaded(uploaded)
    }
  }

  const handleScreenshotRequirementError = (isAdded: boolean): void => {
    setScreenshotRequirementError(isAdded)
  }

  const requiredFields = [
    'appContext',
    'categories',
    'companyName',
    'companyUrl',
    'contactName',
    'contactEmail',
    'description',
    'instructions',
    'name',
    'privacyUrl',
    'productUrl',
    'shortTagLine',
    'supportUrl',
    'tagLine',
    'logo',
    'screenshot1',
    'screenshot2',
    'screenshot3',
  ]
  const areRequiredFieldsMissing = (): boolean => {
    let hasOneScreenshot = false

    if (
      formDiff['screenshot1'] ||
      formDiff['screenshot2'] ||
      formDiff['screenshot3']
    ) {
      hasOneScreenshot = true
    }
    return (
      requiredFields.some((field): boolean => {
        if (
          !formDiff[field] &&
          field !== 'screenshot1' &&
          field !== 'screenshot2' &&
          field !== 'screenshot3'
        ) {
          return true
        } else if (
          field === 'screenshot1' ||
          field === 'screenshot2' ||
          field === 'screenshot3'
        ) {
          return false
        } else if (
          formInitial[field] &&
          Array.isArray(formDiff[field]) &&
          !formDiff[field].length
        ) {
          return true
        } else {
          return false
        }
      }) || !hasOneScreenshot
    )
  }

  const hasEmptyCategoryError = categoryTouched && !formDiff.categories.length

  useEffect(() => {
    metricsService.track(PAGE_VIEWED, {
      pageName: 'Submit Integration',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleValidationState = (isInvalid: boolean, field: string): void => {
    const updatedValidationErrors = { ...validationErrors }
    if (field.includes('screenshot')) {
      const screenshotFields = Object.keys(validationErrors).filter((key) =>
        key.includes('screenshot')
      )
      const setAllScreenshotFieldsFalse = screenshotFields.some(
        (key: string) => validationErrors[key] !== isInvalid || !isInvalid
      )
      screenshotFields.forEach(
        (key) =>
          (updatedValidationErrors[key] = setAllScreenshotFieldsFalse
            ? false
            : isInvalid)
      )
    } else {
      updatedValidationErrors[field] = isInvalid
    }
    setValidationErrors(updatedValidationErrors)
  }

  const disableFormSubmit =
    Object.values(validationErrors).includes(true) || areRequiredFieldsMissing()

  const getFormData = (filledOutFormFields: {
    [key: string]: any
  }): FormData => {
    const isFile = (value: any): boolean => {
      return value instanceof File
    }

    const formData = new FormData()

    for (const field in formDiff) {
      if (formDiff[field]) {
        filledOutFormFields[field] = formDiff[field]
      }
    }

    const {
      logo,
      screenshot1,
      screenshot2,
      screenshot3,
      ...app
    } = filledOutFormFields

    const rawFormData: { [key: string]: any } = {
      ...(isFile(logo) && { logo }),
      ...(isFile(screenshot1) && { screenshot1 }),
      ...(isFile(screenshot2) && { screenshot2 }),
      ...(isFile(screenshot3) && { screenshot3 }),
      app: JSON.stringify({
        ...app,
        ...(!isFile(logo) && { logo }),
        ...(!isFile(screenshot1) && { screenshot1 }),
        ...(!isFile(screenshot2) && { screenshot2 }),
        ...(!isFile(screenshot3) && { screenshot3 }),
        categories: app.categories.map(
          (category: { [key: string]: string }) => category.id
        ),
      }),
    }

    for (const key in rawFormData) {
      if (rawFormData[key]) {
        formData.append(key, rawFormData[key])
      }
    }

    return formData
  }

  const handleFormSave = async (): Promise<void> => {
    setIsSaving(true)

    try {
      const { idBrokerUrl } = getCIUrls()
      const httpConfig = createHttpConfig(token, {
        idBrokerHost: idBrokerUrl,
      })

      const formData = getFormData({})

      await httpService.patch(
        `${getBffBaseUrl()}/v1/integrations/${integration.id}/save`,
        formData,
        httpConfig
      )

      metricsService.track(INTEGRATION_ACTION, {
        type: 'Save',
      })

      navigate(`/my-apps/${integration.id}`, {
        state: {
          clientId: integration.id,
        },
      })
    } catch (err) {
      setHasError(true)
      logError(`An error has occurred while saving the integration ${err}`)
    } finally {
      setIsSaving(false)
    }
  }

  const handleFormSubmit = async (): Promise<void> => {
    setIsSubmitting(true)

    try {
      const { idBrokerUrl } = getCIUrls()
      const httpConfig = createHttpConfig(token, {
        idBrokerHost: idBrokerUrl,
      })

      const filledOutFormFields: { [key: string]: any } = {
        submissionStatus: INTEGRATION_SUBMITTED.toUpperCase(),
      }

      const formData = getFormData(filledOutFormFields)

      await httpService.patch(
        `${getBffBaseUrl()}/v1/integrations/${integration.id}/submit`,
        formData,
        httpConfig
      )

      metricsService.track(INTEGRATION_ACTION, {
        type: 'Submit',
      })

      navigate(`/my-apps/${integration.id}`, {
        state: {
          hasBeenSubmitted: true,
          clientId: integration.id,
        },
      })
    } catch (err) {
      setHasError(true)
      logError(`An error has occurred while submitting the integration ${err}`)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <section className="submit-integration">
      <form className="apphub-form-container">
        <div className="columns medium-12 large-8">
          <h1>{t('apphubSubmission.webExTitle')}</h1>
          <p className="apphub-header__label">
            {t('apphubSubmission.webExLabel1')}
            <Link
              to="/documentation/app-submission-process"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('apphubSubmission.submissionCriteria')}
            </Link>
            {t('apphubSubmission.webExLabel2')}
          </p>
        </div>
        <div className="form-row">
          <div className="columns medium-3">
            <label htmlFor="apphubSubmission-integrationContext">
              <h3>{t('apphubSubmission.integrationContext')}</h3>
              <span>*</span>
            </label>
            <p>{t('apphubSubmission.integrationContextLabel')}</p>
          </div>
          <div className="columns medium-6 medium-offset-1">
            <div className="cardCheckbox">
              <span className="card-checkbox__selected">
                <Icon
                  className={'check-circle'}
                  name="check-circle-active_16"
                />
                <div className={'content'}>
                  <div className={'title_container'}>
                    <Icon name={'camera_16'} />
                    <span className={'title'}>
                      {t('apphubSubmission.referenceCardTitle')}
                    </span>
                  </div>
                  <hr />
                  {t('apphubSubmission.referenceCardDescription')}
                </div>
              </span>
            </div>
          </div>
          <div className="columns medium-2" />
        </div>

        <div className="form-row">
          <div className="columns medium-3">
            <label htmlFor="apphubSubmission-name">
              <h3>{t('apphubSubmission.appName')}</h3>
              <span>*</span>
            </label>
            <p>{t('apphubSubmission.appNameLabel')}</p>
          </div>
          <div className="columns medium-6 medium-offset-1">
            <ActionInput
              name="appName"
              htmlId="apphubSubmission-name"
              placeholder={t('apphubSubmission.appNamePlaceholder')}
              value={formDiff.name}
              onChange={(input: Event): void => {
                setFormDiff({
                  ...formDiff,
                  name: (input.target as HTMLInputElement).value,
                })
              }}
              validation={INTEGRATION_NAME_VALIDATOR}
              handleValidationState={handleValidationState}
            />
          </div>
          <div className="columns medium-2" />
        </div>

        <div className="form-row">
          <div className="columns medium-3">
            <label htmlFor="apphubSubmission-icon">
              <h3>{t('apphubSubmission.icon')}</h3>
              <span>*</span>
            </label>
            <p>{t('apphubSubmission.iconLabel')}</p>
          </div>
          <div className="columns medium-6 medium-offset-1">
            <ImageSelector
              id="logo"
              name="logo"
              shape="circle"
              imageUrl={formInitial.logo}
              presets={[
                { url: defaultOrange, label: t('apphubSubmission.default1') },
                { url: defaultPurple, label: t('apphubSubmission.default2') },
                { url: defaultGreen, label: t('apphubSubmission.default3') },
              ]}
              handleValidationState={handleValidationState}
              onChange={(value: File): void => {
                setFormDiff({
                  ...formDiff,
                  logo: value,
                })
              }}
            />
          </div>
          <div className="columns medium-2" />
        </div>

        <div className="form-row">
          <div className="columns medium-3">
            <label htmlFor="apphubSubmission-testingInstruction">
              <h3>{t('apphubSubmission.testingInstruction')}</h3>
              <span>*</span>
            </label>
            <p>{t('apphubSubmission.testingInstructionLabel')}</p>
          </div>
          <div className="columns medium-6 medium-offset-1">
            <ActionInput
              name="apphubSubmissionTestingInstruction"
              htmlId="apphubSubmission-testingInstruction"
              value={formDiff.instructions}
              onChange={(input: Event): void => {
                setFormDiff({
                  ...formDiff,
                  instructions: (input.target as HTMLInputElement).value,
                })
              }}
              rows={10}
              textArea
              validation={TESTING_INSTRUCTIONS_VALIDATOR}
              handleValidationState={handleValidationState}
            />
          </div>
          <div className="columns medium-2" />
        </div>

        <div className="form-row">
          <div className="columns medium-3">
            <label htmlFor="apphubSubmission-summary">
              <h3>{t('apphubSubmission.summary')}</h3>
              <span>*</span>
            </label>
            <p>{t('apphubSubmission.summaryLabel')}</p>
          </div>
          <div className="columns medium-6 medium-offset-1">
            <ActionInput
              name="apphubSubmissionSummary"
              htmlId="apphubSubmission-summary"
              value={formDiff.tagLine}
              onChange={(input: Event): void => {
                setFormDiff({
                  ...formDiff,
                  tagLine: (input.target as HTMLInputElement).value,
                })
              }}
              rows={10}
              textArea
              validation={SUMMARY_VALIDATOR}
              handleValidationState={handleValidationState}
            />
          </div>
          <div className="columns medium-2" />
        </div>

        <div className="form-row">
          <div className="columns medium-3">
            <label htmlFor="apphubSubmission-tagline">
              <h3>{t('apphubSubmission.tagline')}</h3>
              <span>*</span>
            </label>
            <p>{t('apphubSubmission.taglineLabel')}</p>
          </div>
          <div className="columns medium-6 medium-offset-1">
            <ActionInput
              name="tagline"
              htmlId="apphubSubmission-tagline"
              placeholder={t('apphubSubmission.taglinePlaceholder')}
              value={formDiff.shortTagLine}
              onChange={(input: Event): void => {
                setFormDiff({
                  ...formDiff,
                  shortTagLine: (input.target as HTMLInputElement).value,
                })
              }}
              validation={TAGLINE_VALIDATOR}
              handleValidationState={handleValidationState}
            />
          </div>
          <div className="columns medium-2" />
        </div>

        <div className="form-row">
          <div className="columns medium-3">
            <label htmlFor="apphubSubmission-description">
              <h3>{t('apphubSubmission.description')}</h3>
              <span>*</span>
            </label>
            <p>{t('apphubSubmission.descriptionLabel')}</p>
          </div>
          <div className="columns medium-6 medium-offset-1">
            <ActionInput
              name="apphubSubmissionDescription"
              htmlId="apphubSubmission-description"
              value={formDiff.description}
              onChange={(input: Event): void => {
                setFormDiff({
                  ...formDiff,
                  description: (input.target as HTMLInputElement).value,
                })
              }}
              rows={10}
              textArea
              validation={DESCRIPTION_VALIDATOR}
              handleValidationState={handleValidationState}
            />
          </div>
          <div className="columns medium-2" />
        </div>

        <div className="form-row">
          <div className="columns medium-3">
            <label htmlFor="apphubSubmission-categories">
              <h3>{t('apphubSubmission.categories')}</h3>
              <span>*</span>
            </label>
            <p>{t('apphubSubmission.categoriesLabel')}</p>
          </div>
          <div className="columns medium-6 medium-offset-1">
            {appHubCategory.map(
              (category: { [key: string]: string }, id: number) => {
                return (
                  <Checkbox
                    value={category.id}
                    key={id}
                    label={category.name}
                    htmlId={category.id}
                    checked={
                      !!formDiff.categories.find(
                        (cat: { [key: string]: string }) =>
                          cat.id === category.id
                      )
                    }
                    disabled={
                      formDiff.categories.length === 3 &&
                      !formDiff.categories.find(
                        (cat: { [key: string]: string }) =>
                          cat.id === category.id
                      )
                    }
                    onClick={(): void => {
                      const categoriesArr = [...formDiff.categories]
                      const index = categoriesArr.findIndex(
                        (cat) => cat.id === category.id
                      )
                      if (index > -1) {
                        categoriesArr.splice(index, 1)
                      } else {
                        if (categoriesArr.length < 3) {
                          categoriesArr.push(category)
                        }
                      }
                      setFormDiff({
                        ...formDiff,
                        categories: categoriesArr,
                      })
                      setCategoryTouched(true)
                    }}
                    data-cy={`category-${id}`}
                  />
                )
              }
            )}
            {hasEmptyCategoryError && (
              <InlineErrorMessage
                message={t('apphubSubmission.categoryValidationErrorMessage')}
              ></InlineErrorMessage>
            )}
          </div>
          <div className="columns medium-2" />
        </div>

        <div className="form-row">
          <div className="columns medium-3">
            <label htmlFor="apphubSubmission-landingPage">
              <h3>{t('apphubSubmission.landingPage')}</h3>
              <span>*</span>
            </label>
            <p>{t('apphubSubmission.landingPageLabel')}</p>
          </div>
          <div className="columns medium-6 medium-offset-1">
            <ActionInput
              name="landingPageName"
              htmlId="apphubSubmission-landingPage"
              placeholder={t('apphubSubmission.linksPlaceholder')}
              value={formDiff.productUrl}
              onChange={(input: Event): void => {
                setFormDiff({
                  ...formDiff,
                  productUrl: (input.target as HTMLInputElement).value,
                })
              }}
              validation={WEBSITE_URL_VALIDATOR}
              handleValidationState={handleValidationState}
            />
          </div>
          <div className="columns medium-2" />
        </div>

        <div className="form-row">
          <div className="columns medium-3">
            <label htmlFor="apphubSubmission-devSupport">
              <h3>{t('apphubSubmission.devSupport')}</h3>
              <span>*</span>
            </label>
            <p>{t('apphubSubmission.devSupportLabel')}</p>
          </div>
          <div className="columns medium-6 medium-offset-1">
            <ActionInput
              name="devSupportName"
              htmlId="apphubSubmission-devSupport"
              placeholder={t('apphubSubmission.linksPlaceholder')}
              value={formDiff.supportUrl}
              onChange={(input: Event): void => {
                setFormDiff({
                  ...formDiff,
                  supportUrl: (input.target as HTMLInputElement).value,
                })
              }}
              validation={WEBSITE_URL_AND_EMAIL_VALIDATOR}
              handleValidationState={handleValidationState}
            />
          </div>
          <div className="columns medium-2" />
        </div>

        <div className="form-row">
          <div className="columns medium-3">
            <label htmlFor="apphubSubmission-privacyPolicy">
              <h3>{t('apphubSubmission.privacyPolicy')}</h3>
              <span>*</span>
            </label>
            <p>{t('apphubSubmission.privacyPolicyLabel')}</p>
          </div>
          <div className="columns medium-6 medium-offset-1">
            <ActionInput
              name="privacyPolicyName"
              htmlId="apphubSubmission-privacyPolicy"
              placeholder={t('apphubSubmission.linksPlaceholder')}
              value={formDiff.privacyUrl}
              onChange={(input: Event): void => {
                setFormDiff({
                  ...formDiff,
                  privacyUrl: (input.target as HTMLInputElement).value,
                })
              }}
              validation={WEBSITE_URL_VALIDATOR}
              handleValidationState={handleValidationState}
            />
          </div>
          <div className="columns medium-2" />
        </div>

        <div className="form-row">
          <div className="columns medium-3">
            <label htmlFor="apphubSubmission-company">
              <h3>{t('apphubSubmission.company')}</h3>
              <span>*</span>
            </label>
            <p>{t('apphubSubmission.companyLabel')}</p>
          </div>
          <div className="columns medium-6 medium-offset-1">
            <label htmlFor="apphubSubmission-companyName">
              {' '}
              <h4>{t('apphubSubmission.companyName')}</h4>
            </label>
            <ActionInput
              name="companyName"
              htmlId="apphubSubmission-companyName"
              placeholder={t('apphubSubmission.companyNamePlaceholder')}
              value={formDiff.companyName}
              onChange={(input: Event): void => {
                setFormDiff({
                  ...formDiff,
                  companyName: (input.target as HTMLInputElement).value,
                })
              }}
              validation={REQUIRED_FIELD_VALIDATOR}
              handleValidationState={handleValidationState}
            />
            <label htmlFor="apphubSubmission-companyWebsite">
              {' '}
              <h4>{t('apphubSubmission.companyWebsite')}</h4>
            </label>
            <ActionInput
              name="companyWebsite"
              htmlId="apphubSubmission-companyWebsite"
              placeholder={t('apphubSubmission.companyWebsitePlaceholder')}
              value={formDiff.companyUrl}
              onChange={(input: Event): void => {
                setFormDiff({
                  ...formDiff,
                  companyUrl: (input.target as HTMLInputElement).value,
                })
              }}
              validation={WEBSITE_URL_VALIDATOR}
              handleValidationState={handleValidationState}
            />
          </div>
          <div className="columns medium-2" />
        </div>

        <div className="form-row">
          <div className="columns medium-3">
            <label htmlFor="apphubSubmission-contact">
              <h3>{t('apphubSubmission.contact')}</h3>
              <span>*</span>
            </label>
            <p>{t('apphubSubmission.contactLabel')}</p>
          </div>
          <div className="columns medium-6 medium-offset-1">
            <label htmlFor="apphubSubmission-contactName">
              {' '}
              <h4>{t('apphubSubmission.contactName')}</h4>
            </label>
            <ActionInput
              name="contactName"
              htmlId="apphubSubmission-contactName"
              placeholder={t('apphubSubmission.contactNamePlaceholder')}
              value={formDiff.contactName}
              onChange={(input: Event): void => {
                setFormDiff({
                  ...formDiff,
                  contactName: (input.target as HTMLInputElement).value,
                })
              }}
              validation={NAME_VALIDATOR}
              handleValidationState={handleValidationState}
            />
            <label htmlFor="apphubSubmission-contactEmail">
              {' '}
              <h4>{t('apphubSubmission.contactEmail')}</h4>
            </label>
            <ActionInput
              name="contactEmail"
              htmlId="apphubSubmission-contactEmail"
              placeholder={t('apphubSubmission.contactEmailPlaceholder')}
              value={formDiff.contactEmail}
              onChange={(input: Event): void => {
                setFormDiff({
                  ...formDiff,
                  contactEmail: (input.target as HTMLInputElement).value,
                })
              }}
              validation={EMAIL_VALIDATOR}
              handleValidationState={handleValidationState}
            />

            <label htmlFor="apphubSubmission-ciscoContact">
              {' '}
              <h4>{t('apphubSubmission.ciscoContact')}</h4>
            </label>
            <ActionInput
              name="ciscoContact"
              htmlId="apphubSubmission-ciscoContact"
              placeholder={t('apphubSubmission.ciscoContactPlaceholder')}
              value={formDiff.ciscoContact}
              onChange={(input: Event): void => {
                setFormDiff({
                  ...formDiff,
                  ciscoContact: (input.target as HTMLInputElement).value,
                })
              }}
            />
          </div>
          <div className="columns medium-2" />
        </div>
        <div className="form-row">
          <div className="columns medium-3">
            <label htmlFor="apphubSubmission-screenshots">
              <h3>{t('apphubSubmission.screenshots')}</h3>
              <span>*</span>
            </label>
            <p>{t('apphubSubmission.screenshotsLabel')}</p>
          </div>
          <div className="columns medium-2 medium-offset-1">
            <ScreenshotSelector
              id="screenshot1"
              name="screenshot1"
              shape="rectangle"
              imageUrl={formInitial.screenshot1}
              type={t('apphubSubmission.screenshot1')}
              onChange={(value: File): void => {
                setFormDiff({
                  ...formDiff,
                  screenshot1: value,
                })
              }}
              handleValidationState={handleValidationState}
              handleScreenshotState={handleScreenshotState}
              handleScreenshotRequirementError={
                handleScreenshotRequirementError
              }
            />
          </div>
          <div className="columns medium-2">
            <ScreenshotSelector
              id="screenshot2"
              name="screenshot2"
              shape="rectangle"
              imageUrl={formInitial.screenshot2}
              type={t('apphubSubmission.screenshot2')}
              handleValidationState={handleValidationState}
              onChange={(value: File): void => {
                setFormDiff({
                  ...formDiff,
                  screenshot2: value,
                })
              }}
              handleScreenshotState={handleScreenshotState}
              handleScreenshotRequirementError={
                handleScreenshotRequirementError
              }
            />
          </div>
          <div className="columns medium-2">
            <ScreenshotSelector
              id="screenshot3"
              name="screenshot3"
              shape="rectangle"
              imageUrl={formInitial.screenshot3}
              type={t('apphubSubmission.screenshot3')}
              handleValidationState={handleValidationState}
              onChange={(value: File): void => {
                setFormDiff({
                  ...formDiff,
                  screenshot3: value,
                })
              }}
              handleScreenshotState={handleScreenshotState}
              handleScreenshotRequirementError={
                handleScreenshotRequirementError
              }
            />
          </div>
        </div>
        <div className="medium-offset-4">
          {screenshotRequirementError &&
            !(
              screenshotOneUploaded ||
              screenshotTwoUploaded ||
              screenshotThreeUploaded
            ) && (
              <InlineErrorMessage
                message={t('apphubSubmission.screenshotRequiredMessage')}
              />
            )}
        </div>

        <div className="form-row">
          <div className="columns medium-3">
            <label htmlFor="apphubSubmission-video">
              <h3>{t('apphubSubmission.video')}</h3>
            </label>
            <p>{t('apphubSubmission.videoLabel')}</p>
          </div>
          <div className="columns medium-6 medium-offset-1">
            <ActionInput
              name="videoName"
              htmlId="apphubSubmission-video"
              placeholder={t('apphubSubmission.linksPlaceholder')}
              value={formDiff.videoUrl}
              onChange={(input: Event): void => {
                setFormDiff({
                  ...formDiff,
                  videoUrl: (input.target as HTMLInputElement).value,
                })
              }}
              validation={URL_OR_EMPTY_VALIDATOR}
              handleValidationState={handleValidationState}
            />
          </div>
          <div className="columns medium-2" />
        </div>
        <div className="form-row btn-row">
          <div className="columns">
            <Link
              id="cancel-apphub"
              className="md-button"
              to={`/my-apps/${integration.id}`}
              state={{ clientId: integration.id }}
            >
              {t('actionButtons.cancel')}
            </Link>
            <Button
              id="save-integration"
              disabled={Object.values(validationErrors).includes(true)}
              loading={isSaving}
              onClick={handleFormSave}
              data-testid="save-integration-btn"
              name={t('actionButtons.save')}
            >
              {t('actionButtons.save')}
            </Button>
            <Button
              color="blue"
              id="submit-apphub"
              disabled={disableFormSubmit}
              loading={isSubmitting}
              onClick={handleFormSubmit}
              data-testid="submit-integration-btn"
              name={t('actionButtons.submit')}
            >
              {t('actionButtons.submit')}
            </Button>
          </div>
          <div className="columns medium-2" />
        </div>
      </form>
      <ErrorAlert showAlert={hasError} handleClick={setHasError} />
    </section>
  )
}
export default SubmitIntegration
