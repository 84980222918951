/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { v1 as Uuid } from 'uuid'
import { Button } from '@momentum-ui/react'
import { isImgSizeValid, onFileLoad } from './imageSelector.utils'
import { logError } from '../../services/loggerService'
import InlineMessage from '../InlineErrorMessage'

export interface IPresetProps {
  url: string
  label?: string
}

interface IImageSelectorProps {
  id: string
  onChange: Function
  shape: string
  imageUrl: string
  presets: IPresetProps[]
  handleValidationState: Function
  name: string
}

const ImageSelector: React.FC<IImageSelectorProps> = ({
  id = 'imageSelector',
  shape = 'circle',
  imageUrl,
  presets = [],
  onChange,
  handleValidationState,
  name,
}) => {
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string>(imageUrl || '')
  const [hasValidationErr, setHasValidationErr] = useState(false)
  const [requiredImgErrorMsg, setRequiredImgErrorMsg] = useState(false)
  const { t } = useTranslation()

  let isCancelled = false
  useEffect(() => {
    return (): void => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      isCancelled = true
    }
  }, [])

  const renderPresetImages = (presets: IPresetProps[]): any => {
    let image = null

    if (presets.length) {
      image = presets.map((preset, idx) => {
        const { url, label } = preset

        return (
          <div className={`${'image-option-container'} ${shape}`} key={Uuid()}>
            <div className={'image-container'}>
              <div
                data-testid="preset-icon"
                data-preset={`image-selector-preset-${idx}`}
                tabIndex={-1}
                role="button"
                className={'preset-image'}
                style={{ backgroundImage: `url('${url}')` }}
                onClick={(): void => {
                  selectImage(`${url}`)
                }}
                aria-label={`image-selector-preset-${idx}`}
              />
            </div>
            {label && <div className={`label-text-${idx}`}>{label}</div>}
          </div>
        )
      })
    }

    return image
  }

  const validateImageSelector = (
    value: File | string,
    imgSizeIsValid: boolean
  ): void => {
    if (typeof value !== 'string') {
      imgSizeIsValid ? setHasValidationErr(false) : setHasValidationErr(true)
    } else {
      setHasValidationErr(false)
    }
    setRequiredImgErrorMsg(false)
  }

  const selectImage = async (file: any): Promise<void> => {
    setImagePreviewUrl(file)
    const reader = new FileReader()
    const img = new Image()
    reader.onload = (event: any): void => {
      onFileLoad(img, event, isCancelled, setImagePreviewUrl)
    }
    reader.onerror = (): void => {
      logError(`Error occurred reading file: ${file.name}`)
    }
    img.onload = (): void => {
      const imgSizeIsValid = isImgSizeValid(img.height, img.width, 512, 512)
      validateImageSelector(file, imgSizeIsValid)

      if (!isCancelled) {
        if (handleValidationState) {
          handleValidationState(hasValidationErr, name)
        }
        onChange(file)
      }
    }
    // If choosing a preset image, we have to convert it to a file
    if (file) {
      if (typeof file === 'string') {
        let imageAsFile
        try {
          const imageResponse = await fetch(file)
          const imageAsBlob = await imageResponse.blob()
          imageAsFile = new File([imageAsBlob], file.replace(/\.[^/.]+$/, ''))
        } catch (e) {
          logError(`Error fetching image from filesystem: ${e}`)
        }
        setRequiredImgErrorMsg(false)
        onChange(imageAsFile)
      } else {
        reader.readAsDataURL(file)
      }
    }
  }

  return (
    <>
      <div className={`md-input-group image-container`}>
        {!imagePreviewUrl && (
          <div className={'image-options'}>
            <div className={`${'image-option-container'} ${shape}`}>
              <div className={`${'image-container picker'}`}>
                <div className={'icon-picker-container'}>
                  <i className={`icon icon-upload_32 icon`} />
                </div>
                <input
                  type="file"
                  name={id}
                  id={id}
                  data-testid="uploadInput"
                  accept=".png, .jpeg"
                  onChange={(e: any): void => {
                    e.preventDefault()
                    const file = e.target.files[0]
                    selectImage(file)
                  }}
                />
              </div>
              <div className="label-text-upload">
                {t('apphubSubmission.upload')}
              </div>
            </div>
            {renderPresetImages(presets)}
          </div>
        )}
        {imagePreviewUrl && (
          <div className={'image-options'}>
            <div className={`${'image-option-container'} ${shape}`}>
              <div className={`${'image-container'}`}>
                <div className={'selected-image'}>
                  <img
                    id="preview-img"
                    src={
                      imagePreviewUrl === ''
                        ? 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
                        : imagePreviewUrl
                    }
                    alt={'default'}
                    data-testid={id}
                  />
                </div>
              </div>

              <Button
                data-testid="image-selector-edit"
                className="input-action-btn inline-text-btn"
                onClick={(): void => {
                  setHasValidationErr(false)
                  setRequiredImgErrorMsg(true)
                  onChange('')
                  setImagePreviewUrl('')
                }}
              >
                {t('actionButtons.edit')}
              </Button>
            </div>
          </div>
        )}
      </div>

      {hasValidationErr && (
        <InlineMessage
          message={t('apphubSubmission.imageSelectorValidationMessage')}
        ></InlineMessage>
      )}
      {requiredImgErrorMsg && (
        <InlineMessage
          message={t('apphubSubmission.logoRequiredMessage')}
        ></InlineMessage>
      )}
    </>
  )
}

export default ImageSelector
