import { RouteComponentProps, Router } from '@reach/router'
import { withPrefix } from 'gatsby'
import React, { useEffect, useState } from 'react'
import InternalServerError from '../components/InternalServerError'
import BasicLayout from '../layouts/BasicLayout'
import IntegrationList from '../templates/integrations'
import CreateIntegration from '../templates/integrations/create'
import EditIntegration from '../templates/integrations/edit'
import { getAccessToken, login } from '../utils/auth.utils'
import SubmitIntegration from '../templates/integrations/submit'
import { useSelector } from 'react-redux'
import { retrieveFeatureFlags } from '../state/auth'
import { AppState } from '../state/store'
import { hasValidFeatureFlag } from '../utils/general.utils'
import NewApp from '../templates/apps/new-app'

const RouterPage = (
  props: { component: JSX.Element } & RouteComponentProps
): JSX.Element => {
  return props.component
}

const App: React.FC<RouteComponentProps> = (props: any) => {
  const [showPage, setShowPage] = useState(false)
  const [hasError, setHasError] = useState(false)
  const userFeatureFlags = useSelector((state: AppState) =>
    retrieveFeatureFlags(state)
  )
  const hasAppHubSubmitFeatureFlag = hasValidFeatureFlag(
    userFeatureFlags,
    `${process.env.GATSBY_APPHUB_SUBMIT_NEEDS_FEATURE_FLAG}`,
    `${process.env.GATSBY_APPHUB_SUBMIT_FEATURE_FLAG}`
  )
  const hasServiceAppFeatureFlag = hasValidFeatureFlag(
    userFeatureFlags,
    `${process.env.GATSBY_SERVICE_APP_NEEDS_FEATURE_FLAG}`,
    `${process.env.GATSBY_SERVICE_APP_FEATURE_FLAG}`
  )

  useEffect(() => {
    const checkAuthentication = async (): Promise<void> => {
      const token = await getAccessToken()
      if (token) {
        setShowPage(true)
      } else {
        login()
      }
    }

    checkAuthentication()
  }, [])

  return (
    <>
      {showPage && !hasError ? (
        <BasicLayout>
          <Router className="below-header-container">
            <RouterPage
              path={withPrefix(
                hasServiceAppFeatureFlag
                  ? '/my-apps/new/integration'
                  : '/my-apps/create'
              )}
              component={
                <CreateIntegration
                  hasCurrentIntegrations={
                    props.location.state?.hasCurrentIntegrations
                  }
                />
              }
            />
            {hasServiceAppFeatureFlag ? (
              <RouterPage
                path={withPrefix('/my-apps/new')}
                component={<NewApp />}
              />
            ) : null}
            <RouterPage
              path={withPrefix('/my-apps/:appId')}
              component={
                <EditIntegration
                  clientId={props.location.state?.clientId}
                  clientSecret={props.location.state?.clientSecret}
                  setHasError={setHasError}
                  hasBeenSubmitted={props.location.state?.hasBeenSubmitted}
                />
              }
            />
            <RouterPage
              path={withPrefix('/my-apps')}
              component={<IntegrationList setHasError={setHasError} />}
            />
            {hasAppHubSubmitFeatureFlag ? (
              <RouterPage
                path={withPrefix('/my-apps/:appId/submit')}
                component={
                  <SubmitIntegration
                    integration={props.location.state?.integration}
                  />
                }
              />
            ) : null}
          </Router>
        </BasicLayout>
      ) : null}
      {showPage && hasError ? <InternalServerError /> : null}
    </>
  )
}

export default App
