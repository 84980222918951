import { Button } from '@momentum-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
interface IDialogProps {
  dialogText: string
  onSuccess: Function
  onClose: Function
  showDialog: boolean
  testIdPrefix?: string
}

const Dialog: React.FC<IDialogProps> = ({
  dialogText,
  onSuccess,
  onClose,
  showDialog,
  testIdPrefix,
}) => {
  const { t } = useTranslation()
  const handleClose = (): void => {
    onClose()
  }

  const handleSuccess = (): void => {
    onSuccess()
  }

  return (
    <div className={`md-modal__backdrop fade in ${showDialog ? '' : 'hide'}`}>
      <div
        role="dialog"
        id="dialog"
        className="md-modal md-modal--dialog in"
        aria-labelledby="modalDialog"
        tabIndex={-1}
      >
        <div className="md-modal__content">
          <div className="md-modal__flex-container">
            <div className="md-modal__body">
              <span>{dialogText}</span>
            </div>
            <div className="md-modal__footer">
              <Button
                onClick={handleClose}
                ariaLabel={t('actionButtons.cancel')}
                color="default"
                data-cy={
                  !testIdPrefix
                    ? 'dialog-cancel-btn'
                    : `${testIdPrefix}-dialog-cancel-btn`
                }
              >
                {t('actionButtons.cancel')}
              </Button>
              <Button
                onClick={handleSuccess}
                ariaLabel={t('actionButtons.ok')}
                color="blue"
                data-cy={
                  !testIdPrefix
                    ? 'dialog-submit-btn'
                    : `${testIdPrefix}-dialog-submit-btn`
                }
                data-testid="dialog-submit-btn"
              >
                {t('actionButtons.ok')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dialog
