import React, { useEffect, useState } from 'react'
import { Button } from '@momentum-ui/react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import WebexCCLogo from '../../assets/svgs/webex-cc-logo.svg'
import Integration from '../../components/Integration'
import { INTEGRATION_ACTION, PAGE_VIEWED } from '../../constants/metrics'
import { IIntegration } from '../../interfaces/integrationInterfaces'
import httpService from '../../services/httpService'
import { logError } from '../../services/loggerService'
import MetricsService from '../../services/metricsService'
import { retrieveToken, retrieveFeatureFlags } from '../../state/auth'
import { AppState } from '../../state/store'
import { getBffBaseUrl } from '../../services/configService'
import { getCIUrls } from '../../utils/auth.utils'
import { hasValidFeatureFlag } from '../../utils/general.utils'
import Dialog from '../../components/Dialog'
import { INTEGRATION_NOT_SUBMITTED } from '../../constants/integrations'

interface IEditIntegrationProps {
  clientId: string
  clientSecret?: string
  setHasError: Function
  hasBeenSubmitted?: boolean
}
const EditIntegration: React.FC<IEditIntegrationProps> = ({
  clientId,
  clientSecret,
  setHasError,
  hasBeenSubmitted,
}) => {
  const token = useSelector((state: AppState) => retrieveToken(state))
  const [dataFetched, setDataFetched] = useState<IIntegration | null>(null)
  const [showLoadingScreen, setShowLoadingScreen] = useState<boolean>(true)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [integrationName, setIntegrationName] = useState<string>('')
  const [hasRecentlyBeenSubmitted, setHasRecentlyBeenSubmitted] = useState<
    boolean
  >(hasBeenSubmitted || false)
  const { t } = useTranslation()
  const metricsService = MetricsService.getInstance()
  const { idBrokerUrl } = getCIUrls()
  const [dialogState, setDialogState] = useState<any>({
    isShowing: false,
    type: '',
  })
  const userFeatureFlags = useSelector((state: AppState) =>
    retrieveFeatureFlags(state)
  )
  const hasAppHubSubmitFeatureFlag = hasValidFeatureFlag(
    userFeatureFlags,
    `${process.env.GATSBY_APPHUB_SUBMIT_NEEDS_FEATURE_FLAG}`,
    `${process.env.GATSBY_APPHUB_SUBMIT_FEATURE_FLAG}`
  )

  const renderDialog = (): JSX.Element => {
    const closeDialog = (): void => {
      setDialogState({ isShowing: false })
    }

    return (
      <Dialog
        dialogText={t('integrations.withdrawIntegrationDialogText')}
        onSuccess={(): void => {
          withdrawIntegration()
          closeDialog()
        }}
        onClose={closeDialog}
        showDialog={dialogState?.isShowing}
        testIdPrefix="withdraw"
      />
    )
  }

  const httpConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      idBrokerHost: idBrokerUrl,
    },
  }

  const withdrawIntegration = async (): Promise<void> => {
    setIsSubmitting(true)
    try {
      await httpService.patch(
        `${getBffBaseUrl()}/v1/integrations/${clientId}/withdraw`,
        {
          submissionStatus: INTEGRATION_NOT_SUBMITTED,
          name: dataFetched?.name,
        },
        httpConfig
      )

      metricsService.track(INTEGRATION_ACTION, {
        type: 'Withdraw',
      })

      setHasRecentlyBeenSubmitted(false)
      setDataFetched({
        ...(dataFetched as IIntegration),
        submissionStatus: INTEGRATION_NOT_SUBMITTED,
      })
    } catch (err) {
      setHasError(true)
      logError(`An error has occurred while withdrawing the integration ${err}`)
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    metricsService.track(PAGE_VIEWED, {
      pageName: 'Edit Integrations',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        const httpResponse = await httpService.get(
          `${getBffBaseUrl()}/v1/integrations/${clientId}`,
          httpConfig
        )
        setDataFetched({
          ...httpResponse.data,
          ...(clientSecret && { secret: clientSecret }),
        })
        setIntegrationName(httpResponse.data.name)
      } catch (err) {
        setHasError(true)
        logError(`An error has occurred while fetching the integration ${err}`)
      } finally {
        setShowLoadingScreen(false)
      }
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, token, idBrokerUrl])

  const updateIntegrationName = (name: string): void => {
    setIntegrationName(name)
  }
  return (
    <section className="edit-integration">
      {clientSecret || hasRecentlyBeenSubmitted ? (
        <div className="success-header">
          <h1>{t('integrations.createSuccessHeader')} &#x1f389;</h1>
          {clientSecret ? (
            <h4> {t('integrations.createSuccessSub')} </h4>
          ) : null}
          {hasRecentlyBeenSubmitted && hasAppHubSubmitFeatureFlag ? (
            <div>
              <p>{t('apphubSubmission.submissionSuccessMessage')}</p>
              <p>{t('apphubSubmission.submissionSuccessSupportMessage')}</p>
              <p>{t('apphubSubmission.submissionSuccessEmailMessage')}</p>
            </div>
          ) : null}
        </div>
      ) : null}
      {dataFetched && !showLoadingScreen ? (
        <div className="edit-integration-header">
          <h2>{integrationName}</h2>
          {dataFetched?.submissionStatus?.toLowerCase() === 'submitted' &&
          hasAppHubSubmitFeatureFlag ? (
            <Button
              className="withdraw-btn"
              ariaLabel={t('integrations.withdrawIntegration')}
              onClick={(): void => {
                setDialogState({ isShowing: true, type: 'withdraw' })
              }}
              data-testid="withdraw-btn"
              data-cy="withdraw-btn"
              loading={isSubmitting}
            >
              {t('integrations.withdrawIntegration')}
            </Button>
          ) : null}
        </div>
      ) : null}
      {showLoadingScreen ? (
        <div className="loading-screen">
          <WebexCCLogo className="loading-stuff" />
        </div>
      ) : null}
      {dataFetched && !showLoadingScreen ? (
        <Integration
          integration={dataFetched}
          updateName={updateIntegrationName}
        />
      ) : null}
      {renderDialog()}
    </section>
  )
}

export default EditIntegration
