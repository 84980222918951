import React from 'react'
import { useTranslation } from 'react-i18next'
import Link from '../Link'

interface IMyAppCardProps {
  icon: JSX.Element
  title: string
  description: string
  buttonText: string
  formLink: string
  documentationLink: string
}

const MyAppCard: React.FC<IMyAppCardProps> = ({
  icon,
  title,
  description,
  buttonText,
  formLink,
  documentationLink,
}) => {
  const { t } = useTranslation()

  return (
    <div className="md-card app-type-card">
      <article>
        <div className="type-container">
          <div className="icon-wrapper">{icon}</div>
          <div className="type-name-container">
            <h3>{title}</h3>
          </div>
          <div className="type-description-container">
            <p className="type-description">{description}</p>
          </div>
          <Link to={formLink} className="md-button md-button--blue">
            <span>{buttonText}</span>
          </Link>
          <div className="doc-link-container">
            <Link to={documentationLink}>
              <span>{t('myApps.appTypeCard.learnMore')}</span>
            </Link>
          </div>
        </div>
      </article>
    </div>
  )
}

export default MyAppCard
